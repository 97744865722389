import React from 'react'
import { addWeeks, differenceInCalendarISOWeeks } from 'date-fns'

import { IUtbetalning } from '../../../../Ekonomi.types'
import { formatAmount, formatDateFromDateTime } from '../../../../../../utils/helpers'

import { Box, Grid2 as Grid, Stack, Typography, styled } from '@mui/material'

interface Props {
    utbetalning: IUtbetalning
}

const StyledBox = styled(Box)(({ theme }) => ({
    padding: '32px 0 40px 0',
    '&:not(:last-child)': { borderBottom: `1px solid ${theme.palette.divider}` },
}))

export const KompletterandeStudiestodDetaljer = ({ utbetalning }: Props) => {
    const { startAr, startVecka, slutAr, slutVecka, krPerVecka, nettobelopp, bruttobelopp, underlagstyp, planeratUtbetalningsdatum } =
        utbetalning

    const isAterbetalningOrAvdrag =
        underlagstyp === 'aterbetalningnetto' ||
        underlagstyp === 'aterbetalningbrutto' ||
        underlagstyp === 'avbrutenutbetalning' ||
        underlagstyp === 'avdrag'

    const netto = isAterbetalningOrAvdrag ? -Math.abs(nettobelopp) : Math.abs(nettobelopp)
    const brutto = isAterbetalningOrAvdrag ? -Math.abs(bruttobelopp) : Math.abs(bruttobelopp)
    const krPerVeckaWithSign = krPerVecka ? (isAterbetalningOrAvdrag ? -Math.abs(krPerVecka) : Math.abs(krPerVecka)) : null
    const utbetalningsdatum = formatDateFromDateTime(planeratUtbetalningsdatum)
    const startDate = addWeeks(new Date(Number(startAr), 0, 1), Number(startVecka))
    const endDate = addWeeks(new Date(Number(slutAr), 0, 1), Number(slutVecka))
    const amountOfWeeks = differenceInCalendarISOWeeks(endDate, startDate) + 1

    return (
        <StyledBox>
            <Grid container>
                <Grid size={{ sm: 3, xs: 12 }} sx={{ pb: { xs: 1, md: 0 } }}>
                    <Typography variant="body2">{utbetalningsdatum}</Typography>
                </Grid>

                <Grid size={{ sm: 3, xs: 12 }}>
                    <Typography variant="subtitle2">Belopp per vecka</Typography>
                    <Typography variant="body2">{formatAmount(krPerVeckaWithSign ?? 0)}</Typography>
                </Grid>

                <Grid size={{ sm: 3, xs: 6 }}>
                    <Stack>
                        <Typography variant="subtitle2">Nettobelopp</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {formatAmount(netto)} kr
                        </Typography>
                    </Stack>
                </Grid>

                <Grid size={{ sm: 3, xs: 6 }}>
                    <Stack>
                        <Typography variant="subtitle2">Bruttobelopp</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {formatAmount(brutto)} kr
                        </Typography>
                    </Stack>
                </Grid>

                <Grid size={{ xs: 12 }} mt={1}>
                    <Typography variant="body2" color="text.secondary" pt={2}>
                        {`${amountOfWeeks} veckor, v.${startVecka} ${startAr} - v.${slutVecka} ${slutAr}`}
                    </Typography>
                </Grid>
            </Grid>
        </StyledBox>
    )
}
