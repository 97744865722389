import React from 'react'

import { Typography } from '@mui/material'

export const Leverantor = ({ leverantorTitle, leverantor }: { leverantorTitle: string; leverantor?: string }): JSX.Element => (
    <>
        <Typography variant="subtitle1">{leverantorTitle}</Typography>
        <Typography variant="body2">{leverantor}</Typography>
    </>
)
