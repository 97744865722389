import { useGetArendeMedRattTillAgeQuery } from '@local/src/api'
import { IAgeArende, IArendeMedRattTillAgeModel } from '../../../@types/Arende.types'
import useGetArende from '../../../hooks/useGetArende'

const useGetArendeMedRattTillAge = (): IAgeArende => {
    const arende = useGetArende()
    const request: IArendeMedRattTillAgeModel = { arendeId: arende.id, klientId: arende.klient.id }
    const arendeData = useGetArendeMedRattTillAgeQuery(request)

    return arendeData.data as IAgeArende
}

export default useGetArendeMedRattTillAge
