export enum ArendeTyp {
    Omstallning = 'OmstallningsArende',
    Kompetensstod = 'AnstallningsArende',
    Alla = '',
}

export enum ArendeMarkning {
    Chefsomstallning = 'CHEFSOMSTALLNING',
    Undantagsgrupp = 'VDUNDANTAGSGRUPP',
    ForstarktStod = 'FORSTARKTSTOD',
    StartaForetag = 'STARTAFORETAG',
    Studier = 'STUDIESPECIALIST',
}
export enum OmfattningsbeslutStatus {
    Odefinierad = 'Odefinierad',
    Nytt = 'Nytt',
    Manuellt = 'Manuellt',
    Signerad = 'Signerad',
    Granskad = 'Granskad',
    Attesterad = 'Attesterad',
    EjGodkand = 'Ej godkänd',
    Ersatt = 'Ersatt',
}
export interface IAGEInformation {
    mojligAge: number
    beslut: IAGEBeslut[]
    startdatum: Date
    statusText: string
    avslutsdatum: Date
    avslutad: boolean
    harSamtyckeAkassa: boolean
}

export interface IAGEBeslut {
    startDag: number
    slutDag: number
    summa: number
    antalDagar: number
    krPerDag: number
}

export interface IOtherCase {
    arendeId: string
    arendeTyp: ArendeTyp
    avslutsDatum: string
    radgivareId: string
    radgivareFornamn: string
    radgivareEfternamn: string
}

export interface IMarkning {
    id: ArendeMarkning
    displayText: string
    isSet: boolean
    editable: boolean
}

export interface PayloadAction<T> {
    type: string
    payload: T
}

export interface UpdateMarkningInitializedActionPayload {
    markningId: string
    value: boolean
}

export interface IMeeting {
    id: string
    klientId: string
    startDatumTid: string
    slutDatumTid: string
    radgivareNamn: string
    motesTyp: number
    motesVariant?: string
}

export interface IArendeDetails {
    id: string
    arendeNummer: string
    arendeTyp: ArendeTyp
    omfattningsbeslutStatus: OmfattningsbeslutStatus
    klient: IKlient
    omstallningsArende: IOmstallningsArende
    anstallningsArende: IAnstallningsArende
    ovrigaArenden: IOtherCase[]
    hasStudieansokningar: boolean
    arendeMarkningar: IMarkning[]
    omfattningspaket: IOmfattningspaket[]
    statusDisplayText: string
    infoText: string
    warningTexts: IWarningTexts
    inbokadeMoten: IMeeting[]
}

export interface IArendeMedRattTillAgeModel {
    arendeId: string
    klientId: string
}

export interface IWarningTexts {
    bedomningPagar: string
}

interface IForetag {
    id: string
    namn: string
    organisationsnummer: string
}

interface IAnstallningsuppgifter {
    avgangsvederlagSlutDatum?: Date
    anstallningsDatum: string
    anstallningsgrad: number
    arbetstimmarPerVecka: number
    avgangsvederlagBelopp: number
    befattning: string
    foretag: IForetag
    uppsagningsgrad: number
    uppsagningsDatum: string
}
interface IInkomstuppgifter {
    harUppdateratLon: boolean
    jour: number
    kostLogi: number
    lonerevisionsDatum: string
    manadsLon: number
    provision: number
    sistaDagMedLon: string
    totalLon: number
}

interface IProjekt {
    id: string
    namn: string
    nummer: string
}

export interface IAnstallningsAvslutsInformation {
    avslutadAv: string
    avslutsDatum: string
    avslutskod: string
}

interface IRadgivare {
    id: string
    teamId: string
    fornamn: string
    efternamn: string
    radgivarStatistik: {
        idag: number
        in: number
        inPlusMinus: number
        aktuella: number
    }
}

interface IAnstallningsArende {
    anstallningsuppgifter: IAnstallningsuppgifter
    attesteringsDatum: string
    inkomstuppgifter: IInkomstuppgifter
    ansokanKomplettDatum: string
    arendeStatus: string
    team: {
        id: string
        namn: string
    }
    avslutsInformation: IAnstallningsAvslutsInformation
    radgivare: IRadgivare
    meddelandeFranHandlaggare: string
}

export interface IOmstallningsArende {
    anstallningsuppgifter: IAnstallningsuppgifter
    attesteringsDatum: string
    inkomstuppgifter: IInkomstuppgifter
    ansokanKomplettDatum: string

    hasActiveMatchProfile: boolean
    nonActiveMatchProfileReasons: string[]
    age: IAGEInformation
    meddelandeFranAgeHandlaggare: string
    orsakAnsokan: string
    orsakAnsokanDisplayText: string
    projekt: IProjekt
    radgivare: IRadgivare
    radgivning: IRadgivning
    senasteKontaktDatum: string
    tidigareRadgivare: ITidigareRadgivare[]
}
export interface ITidigareRadgivare {
    id: string
    fornamn: string
    efternamn: string
}
export interface IRadgivning {
    arbetsmarknadsomrade: {
        id: string
        namn: string
    }
    avslutsInformation: {
        avslutsKod: string
        avslutsDatum: string
        radgivareFarAteroppna: boolean
        ageHandlaggareFarAteroppna: boolean
    }
    antalKvarvarandeRadgivningsDagar: number
    team: {
        id: string
        namn: string
    }
    fordelningTeam: {
        id: string
        namn: string
    }
    slutDatum: string
    slutdatumTyp: string
    subStatus: string
    subStatusDisplayText: string
}

export interface IKlient {
    id: string
    fornamn: string
    efternamn: string
    alder: number
    kommun: string
    lan: string
    gatuadress: string
    postnummer: string
    ort: string
    linkedInUrl: string
    telefonnummer: string
    samtyckeHalsa: boolean
    epost: string
    personnummer: string
    folkbokforingsStatus: string
    klientBildFilnamn: string
}

export interface INastaMote {
    startDatumTid: string
    slutDatumTid: string
}

export interface IMarkningResBody {
    arendeId: string
    isSet: boolean
    markning: string
}

export interface IAgeArende {
    arendeId: string
    arendenummer: string
    hasRattTillAgeForArendekontext: boolean
}

export interface IArendeTypTabs {
    caseId: string
    klientId: string
    samtyckeHalsa: boolean
    arendeTyp: string
    inbokadeMoten: IMeeting[]
    caseDetails: IArendeDetails
    fornamn: string
    isSmallScreen: boolean
}

export enum OmstallningsArende {
    TIDSLINJE = 'Tidslinje',
    AKTIVITETER = 'Aktiviteter',
    EKONOMI = 'Ekonomi',
    STUDIER = 'Studier',
    AVSLUT = 'Avslut',
    DOKUMENT = 'Dokument',
    ANDRA = 'Ändra',
}

export enum AnstallningsArende {
    TIDSLINJE = 'Tidslinje',
    EKONOMI = 'Ekonomi',
    STUDIER = 'Studier',
    AVSLUT = 'Avslut',
    DOKUMENT = 'Dokument',
}

export const Tabs = { OmstallningsArende, AnstallningsArende }

export interface ICategory {
    kategori: ArendeMarkning
    kategoriDisplayText: string
    isSet: boolean
    editable: boolean
}
export type MarkningType = 'Studiespecialist'

export interface IOmfattningspaket {
    id: string
    namn: Omfattningspaket
    produkt: IProdukt
    content: string | null
}

interface IProdukt {
    id: string
    namn: string
    tjanster: ITjanst[]
}

export interface ITjanst {
    id: string
    namn: string
}

export enum Omfattningspaket {
    Studiestod = 'Studiestöd',
    Kompetensstod = 'Kompetensstöd',
    KompetensstodBas = 'Kompetensstöd Bas',
    Omstallningsstod = 'Omställningsstöd',
    OmstallningsstodBas = 'Omställningsstöd Bas',
}
