import React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { isFaktura, isInsats } from '../../../Ekonomi.helpers'
import { IUtbetalningsbeslut } from '../../../Ekonomi.types'
import { Chip } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

export const SystemChip = ({ utbetalningsbeslut }: { utbetalningsbeslut: IUtbetalningsbeslut }): JSX.Element => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const kategori = utbetalningsbeslut.kategori
    const insatsLink = utbetalningsbeslut.insatsLink
    const SystemChipLabel = isInsats(kategori) ? 'Fenix' : 'Rillion'
    const link = isInsats(kategori) || isFaktura(kategori) ? insatsLink : ''

    if (!link) return <></>

    if (isMobile) return <Chip label={SystemChipLabel} />

    return (
        <Chip
            sx={{ color: 'primary.main' }}
            label={SystemChipLabel}
            icon={<OpenInNewIcon color="primary" sx={{ fontSize: 'medium' }} />}
            component="a"
            href={link}
            clickable
            rel="noopener noreferrer"
            target="_blank"
        />
    )
}
