import { includes } from 'ramda'

import { IEkonomiContent } from './components/Content/EkonomiContent'
import { IUtbetalningsbeslut, KategoriType, PaymentType } from './Ekonomi.types'
import { IEkonomiCardContent } from './components/EkonomiCard/Content/EkonomiCardContent'
import { formatGuidForSap, getCurrentEnvironment } from '@local/src/utils/helpers'
import { getConfig } from '@local/src/utils'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getPaymentTypeTitle = (paymentType: PaymentType) => {
    switch (paymentType) {
        case 'koptutbildningu4':
        case 'koptutbildningu5':
            return 'Köpt utbildning'
        case 'lonetillagg':
            return 'Lönetillägg'
        case 'utbetalning':
            return 'Utbetalning'
        case 'loneavdrag':
            return 'Löneavdrag'
        case 'aterbetalning':
            return 'Återbetalning'
        case 'avbrutenutbetalning':
            return 'Avbruten utbetalning'
        case 'logi':
            return 'Logi'
        case 'enstakaresor':
            return 'Enstaka resor'
        case 'konsultkostnad':
            return 'Konsultkostnad'
        case 'kontantersattning':
            return 'Kontant ersättning'
        case 'loneutfyllnad':
            return 'Löneutfyllnad'
        case 'praktiklon':
            return 'Praktiklön'
        case 'privatutlagg':
            return 'Privata utlägg'
        case 'programvara':
            return 'Programvara'
        case 'regelbundenresa':
            return 'Regelbundna resor'
        case 'startlon':
            return 'Startlön'
        case 'studielon':
            return 'Studielön'
        case 'utbildningskostnad':
            return 'Utbildningskostnader'
        case 'studieersattning':
            return 'Studieersättning'
        case 'flyttkostnad':
            return 'Flyttkostnad'
        case 'workshop':
            return 'Workshop'
        case 'inskolningslon':
            return 'Inskolningslön'
        case 'komplStudiestod':
            return 'Kompletterande studiestöd'
        case 'komplStudiestodAterbetalning':
            return 'Kompletterande studiestöd - återbetalning'
        case 'kortvarigtstudiestod':
            return 'Kortvarigt studiestöd'
        case 'skattepliktigresa':
            return 'Skattepliktig resa'
        case 'ejskattparesa':
            return 'Ej skattepliktig resa'
        default:
            return ''
    }
}

export const getPaymentType = (beslut: IUtbetalningsbeslut): PaymentType => {
    const kksUnderlagstyp = beslut.utbetalningar?.[0]?.underlagstyp

    switch (kksUnderlagstyp) {
        case 'aterbetalningbrutto':
        case 'aterbetalningnetto':
        case 'avbrutenutbetalning':
            return 'komplStudiestodAterbetalning'
        default:
            return beslut.typ
    }
}

export const createSkapaOmstallningsinsatsLink = (klientIdOnSapFormat: string, sapTransactionType: string, sapUrl: string): string => {
    const crmKeyname = 'CREATEOMSTINSATS'
    const crmType = 'BTSSP'
    const crmAction = 'C'

    return `${sapUrl}?crm-object-type=${crmType}&crm-object-action=${crmAction}&crm-object-value=${sapTransactionType}:${klientIdOnSapFormat}&crm-object-keyname=${crmKeyname}&sap-language=SV`
}

export const filterByKategori = (utbetalningar: IUtbetalningsbeslut[], filterByTypes: KategoriType[]): IUtbetalningsbeslut[] =>
    utbetalningar?.filter((utbetalning) => includes(utbetalning.kategori, filterByTypes)) ?? []

export const isAge = (kategori: KategoriType): boolean => kategori === 'age'
export const isInsats = (kategori: KategoriType): boolean => kategori === 'insats'
export const isFaktura = (kategori: KategoriType): boolean => kategori === 'faktura'
export const isStudieersattning = (kategori: KategoriType): boolean => kategori === 'studieersattning'
export const isKompletterandeStudiestod = (kategori: KategoriType): boolean => kategori === 'kompletterandestudiestod'

export const isIngaGodkandaInsatser = (kategori: KategoriType, status?: string): boolean =>
    isInsats(kategori) && status !== 'godkand' && status !== 'stoppad' && status !== 'borttagen'

export const isIngaGodkandaStudieersattningUtbetalningar = (kategori: KategoriType, status?: string): boolean =>
    isStudieersattning(kategori) && status !== 'godkand' && status !== 'borttagen'

export const isIngaGodkandaKompletterandeStudiestodUtbetalningar = (kategori: KategoriType, status?: string): boolean =>
    isKompletterandeStudiestod(kategori) && status !== 'godkand'

export const isIngaGodkandaUtbetalningar = (kategori: KategoriType, status?: string): boolean =>
    isIngaGodkandaInsatser(kategori, status) ||
    isIngaGodkandaStudieersattningUtbetalningar(kategori, status) ||
    isIngaGodkandaKompletterandeStudiestodUtbetalningar(kategori, status)

export const isNoPaymentsText = (kategori: KategoriType, status?: string): boolean =>
    isInsats(kategori) && (status === 'stoppad' || status === 'borttagen')

export const ekonomiContent: IEkonomiContent = {
    socialaAvgifter: '* Kostnad inklusive eventuella sociala avgifter',
    informationUtbetalningsDagar: 'TRR betalar ut till kunden varje helgfri måndag och onsdag.',

    tabsKeys: {
        alla: 'Alla',
        insatserStudiestod: 'Insatser/Studiestöd',
        age: 'AGE',
    },

    tidigareAgeTitel: 'Observera',
    tidigareAgeText: 'har AGE från tidigare ärende.',

    ageInformation: {
        ageStatus: 'AGE status',
        ageStart: 'AGE start',
        mojligAge: 'Möjlig AGE',
        beloppsInformation: 'Beloppen är bruttobelopp',
        totalSumma: 'Total summa',
        ageBeslut: 'Beslut',
        dagText: 'Dag',
    },
}

export const getUtbetalningTitle = (typ: PaymentType, kategori: KategoriType, kod?: string): string => {
    if (isAge(kategori)) return `${ekonomiContent.tabsKeys.age} - ${getPaymentTypeTitle(typ)}`
    if (isInsats(kategori) || isFaktura(kategori)) return `${getPaymentTypeTitle(typ)}, ${kod}`
    if (isStudieersattning(kategori) || isKompletterandeStudiestod(kategori)) return getPaymentTypeTitle(typ)

    return ''
}

export const ekonomiCardContent: IEkonomiCardContent = {
    statusDatum: 'Statusdatum',
    utbetalningsDatum: 'Utbetalningssdatum',
    bokforingsDatum: 'Bokföringsdatum',
    datumSaknas: 'Datum saknas',
    status: 'Status',
    bruttoBelopp: 'Bruttobelopp',
    nettoBelopp: 'Nettobelopp',
    radgivare: 'Rådgivare',
    antalDagar: 'Dagar',
    avbruten: 'Avbruten',
    termin: 'Termin',
    avvikandeBeloppInformation: 'Notera att bruttobeloppet har justerats och skiljer sig från det som visas på fakturan',
    leverantor: 'Leverantör',
    utbetalningarContent: {
        detaljinformation: 'Detaljinformation',
        ingaInsatsUtbetalningarLabel: 'Denna insats saknar utbetalningar.',
        ingaStudieersattningUtbetalningarLabel: 'Denna studieersättning saknar utbetalningar.',
        ingaKompletterandeStudiestodUtbetalningarLabel: 'Detta kompletterade studiestöd saknar utbetalningar.',
        ingaGodkandaInsatsUtbetalningarLabel: 'Utbetalningar visas när insatsen är godkänd.',
        ingaGodkandaStudieersattningUtbetalningarLabel: 'Utbetalningar visas när studieersättningen är godkänd.',
        ingaGodkandaKompletterandeStudiestodUtbetalningarLabel: 'Utbetalningar visas när det kompletterande studiestödet är godkänt.',
    },
}

export const insatssummaLinkText = (klientId: string) => {
    const klientIdForSAPLink = formatGuidForSap(klientId)

    const envSpecificInsatssummaLink = (env: 'dev' | 'test' | 'prod') => {
        const insatssummaLink = `https://sapbi.trrnet.se/BOE/OpenDocument/opendoc/openDocument.jsp?sIDType=CUID&iDocID=AacXrGSho7hPqA2M0NrS4dU&XBPGUID=${klientIdForSAPLink}`

        return env === 'prod' ? insatssummaLink : '/'
    }

    return envSpecificInsatssummaLink(getCurrentEnvironment(getConfig().API_URL))
}

export const omstallningsinsatsLink = (klientId: string) => {
    const klientIdForSAPLink = formatGuidForSap(klientId)

    return createSkapaOmstallningsinsatsLink(klientIdForSAPLink, getConfig().SAP_TRANSACTION_TYPE, getConfig().SAP_URL)
}
