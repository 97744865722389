import React from 'react'

import { formatAmount, formatDateFromDateTime } from '../../../../utils/helpers'
import { Box, Divider, Grid2 as Grid, Typography } from '@mui/material'
import { IAgeInformationContent } from '../Content/EkonomiContent'
import useAgeInformation from '../../hooks/useAgeInformation'

export const AgeInformation = ({ content }: { content: IAgeInformationContent }): JSX.Element => {
    const ageInformation = useAgeInformation()

    if (!ageInformation) return <></>

    return (
        <div data-testid="ageInformation">
            <Grid container spacing={2}>
                <Grid size={{ xs: 12 }} mb={1} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Typography variant="h5">AGE</Typography>
                </Grid>
                <Grid size={{ xs: 8 }}>
                    <Typography variant="subtitle1">Status</Typography>
                    <Typography variant="body2" color="text.secondary">
                        {ageInformation.statusText}
                    </Typography>
                </Grid>
                <Grid size={{ xs: 4 }}>
                    <Typography variant="body2" align="right">
                        {formatDateFromDateTime(ageInformation.avslutsdatum)}
                    </Typography>
                </Grid>
                <Grid size={{ xs: 6 }}>
                    <Typography variant="body1">AGE start</Typography>
                </Grid>
                <Grid size={{ xs: 6 }}>
                    <Typography variant="body1" align="right">
                        {formatDateFromDateTime(ageInformation.startdatum)}
                    </Typography>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <Divider />
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <Typography variant="h6">Möjlig AGE</Typography>
                    <Typography variant="body2" color="text.secondary">
                        Beloppen är bruttobelopp
                    </Typography>
                </Grid>
                {ageInformation.beslut.map((beslut) => (
                    <Grid size={{ xs: 12 }} key={`${beslut.antalDagar}-${beslut.krPerDag}`}>
                        <Grid size={{ xs: 12 }} sx={{ display: { xs: 'block', md: 'none' } }} mb={2}>
                            <Divider />
                        </Grid>

                        <Grid container>
                            <Grid size={{ xs: 8 }}>
                                <Typography
                                    variant="subtitle1"
                                    mr={2}
                                >{`${content.dagText} ${beslut.startDag} - ${beslut.slutDag}`}</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {`${formatAmount(beslut.krPerDag)} kr/dag`}
                                </Typography>
                            </Grid>

                            <Grid size={{ xs: 4 }}>
                                <Typography variant="body1" align="right">
                                    {`${formatAmount(beslut.krPerDag * beslut.antalDagar)} kr`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
                <Grid size={{ xs: 12 }}>
                    <Divider />
                </Grid>
                <Grid size={{ xs: 8 }}>
                    <Typography variant="body1">{content.totalSumma}</Typography>
                </Grid>
                <Grid size={{ xs: 4 }}>
                    <Typography variant="body1" align="right">
                        <Box sx={{ fontWeight: 'bold' }}>{`${formatAmount(ageInformation.mojligAge)} kr`}</Box>
                    </Typography>
                </Grid>
                <Grid size={{ xs: 12 }} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Divider />
                </Grid>
            </Grid>
        </div>
    )
}
