import useAgeInformation from './useAgeInformation'
import useAvslutsdatum from './useAvslutsdatum'
import useHasAgeFranTidigareArende from './useHasAgeFranTidigareArende'

const useIsEkonomiAsideVisible = () => {
    const avslutsdatum = useAvslutsdatum()
    const ageInformation = useAgeInformation()
    const hasAgeFranTidigareArende = useHasAgeFranTidigareArende()

    const isVisible = hasAgeFranTidigareArende || avslutsdatum || ageInformation

    return isVisible
}
export default useIsEkonomiAsideVisible
