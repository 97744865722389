import useGetArendeId from './useGetArendeId'
import { useGetArendeQuery } from '@local/src/api'

const useIsLoadingArende = (): boolean => {
    const arendeId = useGetArendeId()
    const { isLoading, isFetching } = useGetArendeQuery(arendeId)

    return isLoading || isFetching
}

export default useIsLoadingArende
