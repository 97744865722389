import React from 'react'

import { IUtbetalning, KategoriType } from '../../../../Ekonomi.types'
import { isKompletterandeStudiestod } from '../../../../Ekonomi.helpers'
import { formatAmount, formatDateFromDateTime } from '../../../../../../utils/helpers'

import { Box, Grid2 as Grid, Stack, Typography, styled } from '@mui/material'
import { KompletterandeStudiestodDetaljer } from './KompletterandeStudiestodDetaljer'

interface Props {
    utbetalning: IUtbetalning
    kategori: KategoriType
}

const StyledBox = styled(Box)(({ theme }) => ({
    padding: '16px 0 40px 0',
    '&:not(:last-child)': { borderBottom: `1px solid ${theme.palette.divider}` },
}))

export const UtbetalningsDetaljer = ({ utbetalning, kategori }: Props) => {
    const nettobelopp = Math.abs(utbetalning.nettobelopp)
    const bruttobelopp = Math.abs(utbetalning.bruttobelopp)
    const planeratUtbetalningsdatum = formatDateFromDateTime(utbetalning.planeratUtbetalningsdatum)

    if (isKompletterandeStudiestod(kategori)) return <KompletterandeStudiestodDetaljer utbetalning={utbetalning} />

    return (
        <StyledBox>
            <Grid container>
                <Grid size={{ sm: 3, xs: 12 }} sx={{ pb: { xs: 1, md: 0 } }}>
                    <Typography variant="subtitle1">{planeratUtbetalningsdatum}</Typography>
                </Grid>

                <Grid size={{ sm: 3, xs: 12 }}>
                    <Typography variant="body2">{utbetalning.underlagstyp === 'tillagg' ? 'Tilläggsutbetalning' : ''}</Typography>
                </Grid>

                <Grid size={{ sm: 3, xs: 6 }}>
                    <Stack>
                        <Typography variant="body1">Nettobelopp</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {formatAmount(nettobelopp)} kr
                        </Typography>
                    </Stack>
                </Grid>
                <Grid size={{ sm: 3, xs: 6 }}>
                    <Stack>
                        <Typography variant="body1">Bruttobelopp</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {formatAmount(bruttobelopp)} kr
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </StyledBox>
    )
}
