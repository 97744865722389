import React from 'react'

import { Alert, Stack, Typography } from '@mui/material'

import { formatDateFromDateTime } from '@local/src/utils/helpers'
import useAvslutsdatum from '../../../hooks/useAvslutsdatum'

export const AvgangsvederlagInfo = (): JSX.Element => {
    const avslutsdatum = useAvslutsdatum()

    if (!avslutsdatum) return <></>

    return (
        <Alert severity="info" sx={{ mb: 2 }}>
            <Stack direction="row" spacing={2}>
                <Typography variant="body2" color="info.dark">
                    Avgångsvederlag till och med
                </Typography>

                <Typography variant="body2" color="info.dark" align="right" sx={{ minWidth: '80px' }}>
                    {formatDateFromDateTime(avslutsdatum)}
                </Typography>
            </Stack>
        </Alert>
    )
}

export default AvgangsvederlagInfo
