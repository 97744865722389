import { isNil } from 'ramda'
import { AgeFlag, useHasRightToAge } from '../components/Age/Fragments/AgeFranTidigareArende'
import useGetArendeMedRattTillAge from '../components/Age/Fragments/useGetArendeMedRattTillAge'
import useGetArende from './useGetArende'

const useHasAgeFranTidigareArende = (): boolean => {
    const arende = useGetArende()
    const arendeMedRattTillAge = useGetArendeMedRattTillAge()
    const ageFlag = useHasRightToAge(arende?.omstallningsArende?.age, arendeMedRattTillAge)

    return ageFlag === AgeFlag.JaTidigare && !isNil(arendeMedRattTillAge?.arendeId)
}

export default useHasAgeFranTidigareArende
