interface IConfiguration {
    API_URL: string
    AUTHORITY_URL: string
    AUTHORITY_CLIENT_ID: string
    AI_INSTRUMENTATION_KEY: string
    BW_URL: string
    MEDIA_URL: string
    SAP_URL: string
    SAP_TRANSACTION_TYPE: string
    REPLACEABLE: string
}

// This configuration will be used for local development
const compiletimeConfiguration = {
    API_URL: process.env.API_URL ?? '',
    AUTHORITY_URL: process.env.AUTHORITY_URL ?? '',
    AUTHORITY_CLIENT_ID: process.env.AUTHORITY_CLIENT_ID ?? '',
    AI_INSTRUMENTATION_KEY: process.env.AI_INSTRUMENTATION_KEY ?? '',
    BW_URL: process.env.BW_URL ?? '',
    MEDIA_URL: process.env.MEDIA_URL ?? '',
    SAP_URL: process.env.SAP_URL ?? '',
    SAP_TRANSACTION_TYPE: process.env.SAP_TRANSACTION_TYPE ?? '',
}

// This is the object that will be replaced in the release pipeline, do not change this
const APP_CONFIGURATION = {
    REPLACEABLE: 'CONFIG',
}

const getConfig = (): IConfiguration => ({
    ...compiletimeConfiguration,
    ...APP_CONFIGURATION,
})

export default getConfig
