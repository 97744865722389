import { format } from 'date-fns'

export const formatAmount = (numericAmount: number): string => Math.round(numericAmount).toLocaleString('en').replace(/,/g, ' ')

export const formatDateFromDateTime = (dateTime: string | Date, twoYearDigit = false): string => {
    return twoYearDigit ? dateTime && format(new Date(dateTime), 'yy-MM-dd') : dateTime && format(new Date(dateTime), 'yyyy-MM-dd')
}

export const formatGuidForSap = (dashedLowerCaseGuid: string): string => {
    return dashedLowerCaseGuid?.replace(/-/g, '').toUpperCase()
}

export const getCurrentEnvironment = (stringThatContainsEnvironmentName: string): 'dev' | 'test' | 'prod' =>
    stringThatContainsEnvironmentName.includes('dev') ? 'dev' : stringThatContainsEnvironmentName.includes('test') ? 'test' : 'prod'
