import React from 'react'
import { getAgeHandlaggarMeddelande } from '@local/src/utils/arendeHelpers'
import useGetArende from './hooks/useGetArende'
import { Alert, Box, Stack, Typography } from '@mui/material'

const AgeHandlaggarMeddelande = (): JSX.Element => {
    const arende = useGetArende()
    const ageHandlaggarMeddelande = getAgeHandlaggarMeddelande(arende)

    if (!ageHandlaggarMeddelande) return <></>

    return (
        <Box mb={2}>
            <Alert severity="info">
                <Stack spacing={1}>
                    <Typography variant="subtitle1" color="info.dark">
                        <Box fontWeight="bold">Kommentar från A&E</Box>
                    </Typography>

                    <Typography variant="body2" color="info.dark" style={{ whiteSpace: 'pre-line', overflowWrap: 'break-word' }}>
                        {ageHandlaggarMeddelande}
                    </Typography>
                </Stack>
            </Alert>
        </Box>
    )
}

export default AgeHandlaggarMeddelande
