export const ekonomiTabPage = '*/arende/:arendeId/ekonomi'

export interface IUtbetalning extends IKompletterandeStudiestodUtbetalning {
    betalningId: string
    planeratUtbetalningsdatum: string
    nettobelopp: number
    bruttobelopp: number
}

export type UnderlagsTyp = 'tillagg' | 'standard' | 'aterbetalningbrutto' | 'aterbetalningnetto' | 'avbrutenutbetalning' | 'avdrag'

export interface IKompletterandeStudiestodUtbetalning {
    startAr?: string
    startVecka?: string
    slutAr?: string
    slutVecka?: string
    underlagstyp?: UnderlagsTyp
    krPerVecka?: number
}

export enum BeslutsTyp {
    AGE,
    Studieersättning,
    Insats,
}

export interface IUtbetalningsbeslut
    extends IUtbetalningsbeslutAGE,
        IUtbetalningsbeslutOmstallningsinsats,
        IUtbetalningsbeslutStudieersattning,
        IFaktura,
        IKompletterandeStudieersattning {
    datum: string
    kategori: KategoriType
    typ: PaymentType
    bruttobelopp: number
    insatsLink?: string
}

interface IFaktura {
    fakturaId?: string
    isJusterad?: boolean
    leverantorNamn?: string
}

export interface IUtbetalningsbeslutAGE {
    betalningId: string
    beslutId?: string
    utbetaldaDagar?: number
    nettobelopp?: number
    bruttobelopp?: number
    datum?: string
    typ: PaymentType
    kategori: KategoriType
}

interface IUtbetalningsbeslutOmstallningsinsats {
    beslutId?: string
    kod?: string
    status?: StatusType
    avbruten?: boolean
    belastadRadgivareNamn?: string
    utbetalningar?: IUtbetalning[]
}

interface IUtbetalningsbeslutStudieersattning {
    beslutId?: string
    kod?: string
    status?: StatusType
    avbruten?: boolean
    belastadRadgivareNamn?: string
    utbetalningar?: IUtbetalning[]
}

interface IKompletterandeStudieersattning {
    beslutId?: string
    kod?: string
    status?: StatusType
    avbruten?: boolean
    belastadRadgivareNamn?: string
    utbetalningar?: IUtbetalning[]
}

export type KategoriType = 'age' | 'insats' | 'studieersattning' | 'faktura' | 'kompletterandestudiestod' | 'kortvarigtstudiestod'

export type PaymentType =
    | 'lonetillagg'
    | 'utbetalning'
    | 'loneavdrag'
    | 'aterbetalning'
    | 'avbrutenutbetalning'
    | 'logi'
    | 'enstakaresor'
    | 'konsultkostnad'
    | 'kontantersattning'
    | 'loneutfyllnad'
    | 'praktiklon'
    | 'privatutlagg'
    | 'programvara'
    | 'regelbundenresa'
    | 'startlon'
    | 'studielon'
    | 'utbildningskostnad'
    | 'studieersattning'
    | 'flyttkostnad'
    | 'workshop'
    | 'inskolningslon'
    | 'koptutbildningu4'
    | 'koptutbildningu5'
    | 'komplStudiestod'
    | 'kortvarigtstudiestod'
    | 'skattepliktigresa'
    | 'ejskattparesa'
    | 'komplStudiestodAterbetalning'
    | 'komplStudiestodAvdrag'

export type StatusType =
    | 'godkand'
    | 'preliminar'
    | 'undergodkannande'
    | 'utreds'
    | 'atersand'
    | 'stoppad'
    | 'borttagen'
    | 'ny'
    | 'signerad'
    | 'avvisad'
    | 'granskad'
