import { IUtbetalningsbeslut } from '../Ekonomi.types'
import useGetArendeId from './useGetArendeId'
import { useGetUtbetalningsbeslutQuery } from '@local/src/api'

const useGetUtbetalningar = (): IUtbetalningsbeslut[] => {
    const arendeId = useGetArendeId()
    const arendeData = useGetUtbetalningsbeslutQuery(arendeId)

    return arendeData.data as IUtbetalningsbeslut[]
}

export default useGetUtbetalningar
