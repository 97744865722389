import React from 'react'

import { getUtbetalningTitle, isFaktura } from '../../Ekonomi.helpers'
import { IUtbetalningsbeslut, KategoriType } from '../../Ekonomi.types'

import { Kategori, SystemChip, AvbrutenIndicator, BelastadRadgivare, Datum, Bruttobelopp, Status, Leverantor } from './Fragments'

import { IEkonomiCardContent } from './Content/EkonomiCardContent'
import { Typography, Grid2 as Grid, Box, Alert } from '@mui/material'

interface EkonomiCardContentProps {
    utbetalningsbeslut: IUtbetalningsbeslut
    content?: IEkonomiCardContent
    kategori: KategoriType
}

export const EkonomiCardContent = ({ utbetalningsbeslut, content, kategori }: EkonomiCardContentProps) => {
    const insatsKod = utbetalningsbeslut.kod === 'KV_TIA' ? 'KV' : utbetalningsbeslut.kod

    if (!content) return <></>

    return (
        <Grid container spacing={1}>
            <Grid size={{ xs: 12 }} pb={2}>
                <Typography variant="h5">{getUtbetalningTitle(utbetalningsbeslut.typ, utbetalningsbeslut.kategori, insatsKod)}</Typography>
            </Grid>

            <Grid size={{ xs: 6 }} pb={2}>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '8px',
                        minWidth: '100%',
                    }}
                >
                    <Kategori kategori={kategori} />
                    <SystemChip utbetalningsbeslut={utbetalningsbeslut} />
                    <AvbrutenIndicator avbruten={utbetalningsbeslut.avbruten ?? false} avbrutenContent={content.avbruten} />
                </Box>
            </Grid>

            <Grid size={{ xs: 6, sm: 3 }} sx={{ display: { xs: 'none', md: 'block' } }} />

            <Grid size={{ xs: 6, sm: 3 }} sx={{ display: 'flex', justifyContent: 'flex-start', pb: 2 }}>
                <Status status={utbetalningsbeslut.status} kategori={utbetalningsbeslut.kategori} />
            </Grid>

            <Grid size={{ xs: 12, sm: 3 }} mb={{ xs: 2 }}>
                <BelastadRadgivare
                    belastadRadgivareNamn={utbetalningsbeslut.belastadRadgivareNamn ?? ''}
                    belastadRadgivareContent={content.radgivare}
                    kategori={utbetalningsbeslut.kategori}
                />
            </Grid>
            <Grid size={{ xs: 6, sm: 3 }}>
                <Datum
                    datum={utbetalningsbeslut.datum}
                    statusDatumLabel={content.statusDatum}
                    datumSaknasLabel={content.datumSaknas}
                    utbetalningsDatumLabel={content.utbetalningsDatum}
                    bokforingsDatumLabel={content.bokforingsDatum}
                    kategori={utbetalningsbeslut.kategori}
                />
            </Grid>
            <Grid size={{ xs: 6, sm: 3 }} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Bruttobelopp
                    bruttobelopp={utbetalningsbeslut.bruttobelopp}
                    betalningsTyp={utbetalningsbeslut.typ}
                    bruttobeloppContent={content.bruttoBelopp}
                    kategori={utbetalningsbeslut.kategori}
                />
            </Grid>

            {isFaktura(utbetalningsbeslut.kategori) && (
                <Grid size={{ xs: 12 }} pt={2}>
                    <Leverantor leverantor={utbetalningsbeslut.leverantorNamn} leverantorTitle={content.leverantor} />
                    {utbetalningsbeslut.isJusterad && (
                        <Box mt={2}>
                            <Alert severity="info">{content.avvikandeBeloppInformation}</Alert>
                        </Box>
                    )}
                </Grid>
            )}
        </Grid>
    )
}
