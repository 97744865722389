import React from 'react'
import { IUtbetalning, KategoriType, PaymentType } from '../../../Ekonomi.types'
import { Divider, Typography } from '@mui/material'
import UtbetalningsDetaljer from './UtbetalningsDetaljer'

interface Props {
    utbetalningar?: IUtbetalning[]
    kategori: KategoriType
    betalningsTyp: PaymentType
}

export const GenomfordaUtbetalningar = ({ utbetalningar, kategori, betalningsTyp }: Props) => {
    if (!utbetalningar || utbetalningar.length === 0) return null

    const titleText = betalningsTyp === 'komplStudiestodAterbetalning' ? 'Genomförda återbetalningar' : 'Genomförda utbetalningar'
    return (
        <>
            <Typography variant="subtitle2" pt={2} pb={1}>
                {titleText}
            </Typography>

            <Divider />

            <ol>
                {utbetalningar.map((utbetalning: IUtbetalning) => (
                    <UtbetalningsDetaljer
                        key={`${utbetalning.planeratUtbetalningsdatum}-${utbetalning.betalningId}`}
                        utbetalning={utbetalning}
                        kategori={kategori}
                    />
                ))}
            </ol>
        </>
    )
}
