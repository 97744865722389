import { ArendeTyp, IArendeDetails } from '../pages/Ekonomi/@types/Arende.types'

export const isKompetensstodsArende = (arendeTyp: string): boolean => arendeTyp === String(ArendeTyp.Kompetensstod)

export const isOmstallningsArende = (arendeTyp: string): boolean => arendeTyp === String(ArendeTyp.Omstallning)

export const getAgeHandlaggarMeddelande = (arende: IArendeDetails) =>
    isKompetensstodsArende(arende?.arendeTyp)
        ? arende?.anstallningsArende?.meddelandeFranHandlaggare
        : arende?.omstallningsArende?.meddelandeFranAgeHandlaggare
