import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getIdToken } from '@trr/app-shell-data'
import { getConfig } from '../utils'
import { IAgeArende, IArendeDetails, IArendeMedRattTillAgeModel } from '../pages/Ekonomi/@types/Arende.types'
import { IUtbetalningsbeslut } from '../pages/Ekonomi/Ekonomi.types'

const { API_URL } = getConfig()

const trrFetchBaseQuery = () =>
    fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${getIdToken() ?? ''}`)
            headers.set('Content-Type', 'application/json')
            headers.set('Accept-Language', 'sv')
            return headers
        },
    })

const api = createApi({
    baseQuery: trrFetchBaseQuery(),
    endpoints: (builder) => ({
        getArende: builder.query<IArendeDetails, string | null>({
            query: (arendeId: string) => `/v2.0/arenden/${arendeId}`,
        }),
        getUtbetalningsbeslut: builder.query<IUtbetalningsbeslut[], string | null>({
            query: (arendeId: string) => `/v1.0/ekonomi/arende/${arendeId}/utbetalningsbeslut`,
        }),
        getArendeMedRattTillAge: builder.query<IAgeArende, IArendeMedRattTillAgeModel>({
            query: ({ arendeId, klientId }) => `/v1.0/agearende/klient/${klientId}/arende/${arendeId}/rattTillAge`,
        }),
    }),
})

export const { useGetArendeQuery, useGetUtbetalningsbeslutQuery, useGetArendeMedRattTillAgeQuery } = api

export default api
