/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import React from 'react'
import { KategoriType } from '../../../Ekonomi.types'
import { Chip } from '@mui/material'

export enum KategoriEnum {
    age = 'AGE',
    insats = 'Insats',
    studieersattning = 'Studiestöd',
    faktura = 'Insats',
    kompletterandestudiestod = 'Studiestöd',
    kortvarigtstudiestod = 'Studiestöd',
}
export const Kategori = ({ kategori }: { kategori: KategoriType }): JSX.Element => <Chip label={KategoriEnum[kategori]} />
