import React from 'react'

import { isInsats, isKompletterandeStudiestod, isStudieersattning } from '../../../Ekonomi.helpers'
import { KategoriType, StatusType } from '../../../Ekonomi.types'
import CircleIcon from '@mui/icons-material/Circle'

import { Stack, Typography } from '@mui/material'

enum StatusMap {
    godkand = 'Godkänd',
    preliminar = 'Preliminär',
    undergodkannande = 'Under godkännande',
    utreds = 'Utreds',
    atersand = 'Återsänd',
    stoppad = 'Stoppad',
    borttagen = 'Borttagen',
    ny = 'Ny',
    signerad = 'Signerad',
    avvisad = 'Avvisad',
    granskad = 'Granskad',
}

const statusColor = (status: string) => {
    switch (status) {
        case 'godkand':
            return 'secondary.main'

        case 'atersand':
        case 'avvisad':
        case 'stoppad':
            return 'warning.main'

        case 'borttagen':
            return 'neutral.disabled'

        case 'ny':
            return 'primary.main'

        case 'preliminar':
        case 'undergodkannande':
        case 'signerad':
        case 'granskad':
        case 'utreds':
            return 'info.main'

        default:
            return 'secondary.main'
    }
}

export const Status = ({ status, kategori }: { status?: StatusType; kategori: KategoriType }): JSX.Element =>
    status && (isInsats(kategori) || isStudieersattning(kategori) || isKompletterandeStudiestod(kategori)) ? (
        <Stack direction="row" alignItems="center" spacing={1}>
            <CircleIcon sx={{ fontSize: 'small', color: statusColor(status) }} />
            <Typography variant="body2">{StatusMap[status]}</Typography>
        </Stack>
    ) : (
        <></>
    )
