import React from 'react'
import { Provider } from 'react-redux'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { createTheme, ThemeProvider } from '@mui/material'
import Ekonomi from './pages/Ekonomi'
import { store } from './store'

type Content = {
    heading: string
}

const Index = ({ appShellData }: { appShellData: AppShellDataProps<Content> }) => {
    const theme = createTheme(themeOptions, defaultLocale)

    return (
        <ThemeProvider theme={theme}>
            <AppShellDataProvider value={appShellData}>
                <Provider store={store}>
                    <Ekonomi />
                </Provider>
            </AppShellDataProvider>
        </ThemeProvider>
    )
}

export default Index
