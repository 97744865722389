import React from 'react'

import { Alert, Link, Stack, Typography } from '@mui/material'

import { IAGEInformation, IAgeArende } from '../../../@types/Arende.types'

import useGetArendeMedRattTillAge from './useGetArendeMedRattTillAge'
import useHasAgeFranTidigareArende from '../../../hooks/useHasAgeFranTidigareArende'

const buildLink = (arendeId: string) => `/medarbetare/arenden/alla/arende/${arendeId}/ekonomi`

export enum AgeFlag {
    Ja = 'Ja',
    JaTidigare = 'Ja, från tidigare',
    Nej = 'Nej',
}

export const useHasRightToAge = (age: IAGEInformation, arendeMedRattTillAge: IAgeArende): AgeFlag => {
    if (!age) return AgeFlag.Nej

    const hasAge = arendeMedRattTillAge?.hasRattTillAgeForArendekontext
    return hasAge ? AgeFlag.Ja : AgeFlag.JaTidigare
}

export const AgeFranTidigareArende = (): JSX.Element => {
    const arendeMedRattTillAge = useGetArendeMedRattTillAge()
    const hasAgeFranTidigareArende = useHasAgeFranTidigareArende()

    if (!hasAgeFranTidigareArende) return <></>

    return (
        <Alert severity="info" sx={{ mb: 2 }}>
            <Stack direction="row" spacing={2}>
                <Typography variant="body2">Det finns AGE från ett tidigare ärende</Typography>
                <Link href={buildLink(arendeMedRattTillAge?.arendeId)} target="_blank" rel="noopener noreferrer" sx={{ minWidth: '80px' }}>
                    Visa ärendet
                </Link>
            </Stack>
        </Alert>
    )
}

export default AgeFranTidigareArende
