import React from 'react'
import { IUtbetalning, KategoriType } from '../../../Ekonomi.types'
import { Divider, Typography } from '@mui/material'
import UtbetalningsDetaljer from './UtbetalningsDetaljer'

export const KommandeUtbetalningar = ({ utbetalningar, kategori }: { utbetalningar?: IUtbetalning[]; kategori: KategoriType }) => {
    if (!utbetalningar || utbetalningar.length === 0) return null

    return (
        <>
            <Typography variant="subtitle2" pt={2} pb={1}>
                Kommande utbetalningar
            </Typography>

            <Divider />

            <ol>
                {utbetalningar.map((utbetalning: IUtbetalning) => (
                    <UtbetalningsDetaljer
                        key={`${utbetalning.planeratUtbetalningsdatum}-${utbetalning.betalningId}`}
                        utbetalning={utbetalning}
                        kategori={kategori}
                    />
                ))}
            </ol>
        </>
    )
}
