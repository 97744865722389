import { Chip } from '@mui/material'
import React from 'react'

interface TabChipProps {
    label: string
    currentLabel: string
}

const TabChip = ({ label, currentLabel }: TabChipProps): JSX.Element => {
    return label === currentLabel ? <Chip label={label} color="secondary" variant="filled" clickable /> : <Chip label={label} clickable />
}

export default TabChip
