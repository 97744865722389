import React from 'react'

import { getPaymentType, isAge, isIngaGodkandaUtbetalningar, isNoPaymentsText } from '../../Ekonomi.helpers'
import { IUtbetalningsbeslut } from '../../Ekonomi.types'

import { Utbetalningar } from './Fragments'

import { IEkonomiCardContent } from './Content/EkonomiCardContent'
import { AccordionSummary, AccordionDetails, Accordion, Card } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AgeDetaljer } from './Utbetalningar/UtbetalningsDetaljer/AgeDetaljer'
import { EkonomiCardContent } from './EkonomiCardContent'

interface Props {
    utbetalningsbeslut: IUtbetalningsbeslut
    content?: IEkonomiCardContent
}

export const EkonomiCard = ({ utbetalningsbeslut, content }: Props) => {
    const paymentWithUpdatedType: IUtbetalningsbeslut = { ...utbetalningsbeslut, typ: getPaymentType(utbetalningsbeslut) }
    const { utbetalningar, status, typ } = paymentWithUpdatedType

    const kategori = typ === 'kortvarigtstudiestod' ? typ : paymentWithUpdatedType.kategori
    const hasPayments =
        isIngaGodkandaUtbetalningar(kategori, status) || isNoPaymentsText(kategori, status) || (utbetalningar && utbetalningar.length > 0)

    const expandMoreIconPadding = hasPayments ? 0 : 5

    if (!content) return <></>

    if (isAge(kategori)) return <AgeDetaljer utbetalning={paymentWithUpdatedType} />

    if (!hasPayments)
        return (
            <Card
                variant="outlined"
                data-testid={`ekonomi-utbetalning-${utbetalningsbeslut.beslutId}`}
                sx={{
                    my: 2,
                    p: 2,
                    alignItems: 'flex-start',
                    borderRadius: 1,
                    paddingRight: '55px',
                }}
            >
                <EkonomiCardContent utbetalningsbeslut={paymentWithUpdatedType} content={content} kategori={kategori} />
            </Card>
        )

    return (
        <Accordion data-testid={`ekonomi-utbetalning-${paymentWithUpdatedType.beslutId}`} sx={{ borderRadius: 1 }}>
            <AccordionSummary
                expandIcon={hasPayments ? <ExpandMoreIcon sx={{ my: 2, mx: 1 }} /> : null}
                sx={{ m: 2, p: 0, alignItems: 'flex-start', paddingRight: expandMoreIconPadding }}
            >
                <EkonomiCardContent utbetalningsbeslut={paymentWithUpdatedType} content={content} kategori={kategori} />
            </AccordionSummary>

            <AccordionDetails>
                {hasPayments && (
                    <Utbetalningar
                        utbetalningar={utbetalningar}
                        betalningsTyp={typ}
                        status={status}
                        content={content.utbetalningarContent}
                        kategori={kategori}
                    />
                )}
            </AccordionDetails>
        </Accordion>
    )
}
