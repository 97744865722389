import React from 'react'

import { isFaktura, isInsats } from '../../../Ekonomi.helpers'
import { KategoriType } from '../../../Ekonomi.types'

import { Stack, Typography } from '@mui/material'

export const BelastadRadgivare = ({
    belastadRadgivareContent,
    belastadRadgivareNamn,
    kategori,
}: {
    belastadRadgivareContent: string
    belastadRadgivareNamn: string
    kategori: KategoriType
}): JSX.Element =>
    isInsats(kategori) || (isFaktura(kategori) && belastadRadgivareNamn) ? (
        <Stack direction="column">
            <Typography variant="body1">{belastadRadgivareContent}</Typography>
            <Typography variant="body2">{belastadRadgivareNamn}</Typography>
        </Stack>
    ) : (
        <></>
    )
