import React from 'react'

import { KategoriType } from '../../../Ekonomi.types'
import { isStudieersattning } from '../../../Ekonomi.helpers'

import { Typography } from '@mui/material'

export type TerminType = 'S1' | 'S2' | 'S3' | 'S4'

export enum TerminEnum {
    S1 = '1',
    S2 = '2',
    S3 = '3',
    S4 = '4',
}

export const Termin = ({
    terminContent,
    kod,
    kategori,
}: {
    terminContent: string
    kod: TerminType
    kategori: KategoriType
}): JSX.Element => (isStudieersattning(kategori) ? <Typography variant="body2">{`${terminContent} ${TerminEnum[kod]}`}</Typography> : <></>)
