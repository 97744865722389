import React from 'react'

import { ekonomiContent } from './Ekonomi.helpers'

import { Stack, Typography } from '@mui/material'
import useGetArende from './hooks/useGetArende'
import { AgeFranTidigareArende } from './components/Age/Fragments'
import { AgeInformation } from './components/Age/AgeInformation'
import { isKompetensstodsArende } from '@local/src/utils/arendeHelpers'
import AvgangsvederlagInfo from './components/Age/Fragments/AvgangsvederlagInfo'
import EkonomiAsideLinks from './EkonomiAsideLinks'
import BoxAccordion from '@local/src/components/BoxAccordion/BoxAccordion'
import useIsEkonomiAsideVisible from './hooks/useIsEkonomiAsideVisible'
import useIsMobile from '@local/src/utils/useIsMobile'

const EkonomiAside = () => {
    const arende = useGetArende()
    const isKompetensstodArende = isKompetensstodsArende(arende?.arendeTyp)
    const isMobile = useIsMobile()
    const hasEkonomiInfo = useIsEkonomiAsideVisible()

    if (isKompetensstodArende || (!hasEkonomiInfo && isMobile)) return null

    return (
        <BoxAccordion header={<Typography variant="h5">AGE</Typography>}>
            <Stack direction="column" spacing={2}>
                <EkonomiAsideLinks />
                <AgeFranTidigareArende />
                <AvgangsvederlagInfo />
                <AgeInformation content={ekonomiContent.ageInformation} />
            </Stack>
        </BoxAccordion>
    )
}

export default EkonomiAside
