import React, { ReactElement, ReactNode, UIEvent } from 'react'
import { Box, ClickAwayListener, IconButton, Popper, PopperProps, Stack, Typography, styled } from '@mui/material'

const FilterWrapper = styled(Box)(({ theme }) => ({
    background: 'white',
    boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.2)',
    display: 'inline-block',
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(1),
}))

const Content = styled(Stack)(({ theme }) => ({
    maxHeight: 670,
    padding: theme.spacing(3),
    overflowY: 'auto',
}))

const PopperHeader = styled(Box)<{ $isscrolling: boolean }>(({ theme, $isscrolling }) => ({
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3),
    borderBottom: '1px solid',
    borderColor: $isscrolling ? theme.palette.divider : 'transparent',
    transition: 'all 0.2s ease',
}))

const PopperFooter = styled(Stack)<{ isscrollable: boolean }>(({ theme, isscrollable }) => ({
    padding: theme.spacing(1),
    borderTop: '1px solid',
    borderColor: isscrollable ? theme.palette.divider : 'transparent',
    transition: 'all 0.2s ease',
}))

interface CustomPopperProps extends PopperProps {
    label: string
    headerIcon: ReactNode
    open: boolean
    anchor: HTMLElement
    children: ReactElement
    header?: ReactNode
    footer?: ReactNode
    onClickAway: () => void
}

export const CustomPopper = ({ label, headerIcon, header, footer, open, anchor, onClickAway, children, ...props }: CustomPopperProps) => {
    const [isScrolling, setIsScrolling] = React.useState(false)

    const handleScroll = (event: UIEvent<HTMLDivElement>) => {
        setIsScrolling((event.target as HTMLDivElement)?.scrollTop !== 0)
    }

    return (
        <Popper
            open={open}
            placement="bottom-start"
            anchorEl={anchor}
            modifiers={[
                {
                    name: 'flip',
                    enabled: false,
                },
            ]}
            sx={{ zIndex: 3 }}
            {...props}
        >
            <ClickAwayListener onClickAway={onClickAway}>
                <FilterWrapper>
                    {header && (
                        <PopperHeader $isscrolling={isScrolling}>
                            <Typography variant="body1" fontWeight="bold">
                                {label}
                            </Typography>
                            <IconButton sx={{ position: 'absolute', right: 15 }}>{headerIcon}</IconButton>
                        </PopperHeader>
                    )}
                    <Content onScroll={handleScroll}>
                        <Stack spacing={2}>{children}</Stack>
                    </Content>

                    {footer && <PopperFooter isscrollable={isScrolling}>{footer}</PopperFooter>}
                </FilterWrapper>
            </ClickAwayListener>
        </Popper>
    )
}
