import React, { FC, useMemo } from 'react'

import { isFaktura, isInsats } from '../../../Ekonomi.helpers'
import { KategoriType, PaymentType } from '../../../Ekonomi.types'

import { formatAmount } from '@local/src/utils/helpers'
import { Stack, Typography } from '@mui/material'

interface IBruttoBelopp {
    bruttobelopp: number
    betalningsTyp: PaymentType
    bruttobeloppContent: string
    kategori: KategoriType
}

export const Bruttobelopp: FC<React.PropsWithChildren<IBruttoBelopp>> = ({
    bruttobelopp,
    bruttobeloppContent,
    kategori,
    betalningsTyp,
}) => {
    const isAterbetalningOrAvdrag = betalningsTyp === 'komplStudiestodAterbetalning' || betalningsTyp === 'komplStudiestodAvdrag'
    const formattedAmount = formatAmount(Math.abs(bruttobelopp)) + ' kr'
    const belopp = useMemo(() => {
        if (isFaktura(kategori)) {
            return `${formatAmount(bruttobelopp)} kr`
        } else if (isAterbetalningOrAvdrag) {
            return `-${formattedAmount}`
        } else {
            return formattedAmount
        }
    }, [kategori, isAterbetalningOrAvdrag, formattedAmount, bruttobelopp])

    return (
        <Stack direction="column">
            <Typography variant="body1">{`${bruttobeloppContent}${isInsats(kategori) ? '*' : ''} `}</Typography>
            <Typography variant="body2" color="text.secondary">
                {belopp}
            </Typography>
        </Stack>
    )
}
