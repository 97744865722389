import { IArendeDetails } from '../@types/Arende.types'
import useGetArendeId from '../hooks/useGetArendeId'
import { useGetArendeQuery } from '@local/src/api'

const useGetArende = (): IArendeDetails => {
    const arendeId = useGetArendeId()
    const arendeData = useGetArendeQuery(arendeId)

    return arendeData.data as IArendeDetails
}
export default useGetArende
