import React from 'react'

import WarningIcon from '@mui/icons-material/Warning'
import { Chip } from '@mui/material'

interface IAvbrutenIndicator {
    avbruten: boolean
    avbrutenContent: string
}

export const AvbrutenIndicator = ({ avbruten, avbrutenContent }: IAvbrutenIndicator): JSX.Element =>
    avbruten ? <Chip label={avbrutenContent} icon={<WarningIcon sx={{ fontSize: 'medium', fill: '#bf072f' }} />} /> : <></>
