import React from 'react'

import { UtbetalningarContent } from '../Content/EkonomiCardContent'

import { IUtbetalning, KategoriType, PaymentType, StatusType } from '../../../Ekonomi.types'

import {
    isIngaGodkandaInsatser,
    isIngaGodkandaKompletterandeStudiestodUtbetalningar,
    isIngaGodkandaStudieersattningUtbetalningar,
} from '../../../Ekonomi.helpers'

import { Alert, Typography } from '@mui/material'
import { KommandeUtbetalningar } from './KommandeUtbetalningar'
import { GenomfordaUtbetalningar } from './GenomfordUtbetalningar'

const hasOccured = (datum: string) => new Date(datum) < new Date()

const renderIngaUtbetalningarLabel = (label: string) => (
    <Alert severity="info">
        <Typography variant="body2" color="info.dark">
            {label}
        </Typography>
    </Alert>
)

const renderUtbetalningsInformation = (
    utbetalningar: IUtbetalning[],
    betalningsTyp: PaymentType,
    content: UtbetalningarContent,
    kategori: KategoriType,
    status?: StatusType
) => {
    if (isIngaGodkandaInsatser(kategori, status)) return renderIngaUtbetalningarLabel(content.ingaGodkandaInsatsUtbetalningarLabel)

    if (isIngaGodkandaStudieersattningUtbetalningar(kategori, status))
        return renderIngaUtbetalningarLabel(content.ingaGodkandaStudieersattningUtbetalningarLabel)

    if (isIngaGodkandaKompletterandeStudiestodUtbetalningar(kategori, status))
        return renderIngaUtbetalningarLabel(content.ingaGodkandaKompletterandeStudiestodUtbetalningarLabel)

    if (utbetalningar.length === 0) {
        switch (kategori) {
            case 'studieersattning':
                return renderIngaUtbetalningarLabel(content.ingaStudieersattningUtbetalningarLabel)

            case 'kompletterandestudiestod':
                return renderIngaUtbetalningarLabel(content.ingaKompletterandeStudiestodUtbetalningarLabel)

            default:
                return renderIngaUtbetalningarLabel(content.ingaInsatsUtbetalningarLabel)
        }
    }

    const genomfordaUtbetalningar = utbetalningar.filter((utbetalning) => hasOccured(utbetalning.planeratUtbetalningsdatum))
    const kommandeUtbetalningar = utbetalningar.filter((utbetalning) => !hasOccured(utbetalning.planeratUtbetalningsdatum))

    return (
        <>
            <GenomfordaUtbetalningar utbetalningar={genomfordaUtbetalningar} kategori={kategori} betalningsTyp={betalningsTyp} />
            <KommandeUtbetalningar utbetalningar={kommandeUtbetalningar} kategori={kategori} />
        </>
    )
}

export const Utbetalningar = ({
    utbetalningar,
    betalningsTyp,
    status,
    content,
    kategori,
}: {
    utbetalningar?: IUtbetalning[]
    betalningsTyp: PaymentType
    status?: StatusType
    content: UtbetalningarContent
    kategori: KategoriType
}) =>
    utbetalningar ? (
        <div data-testid="detaljinformation">{renderUtbetalningsInformation(utbetalningar, betalningsTyp, content, kategori, status)}</div>
    ) : null
