import { Tab } from '@mui/material'
import React from 'react'
import TabChip from './TabChip'

interface StyledTabProps {
    label: string
    currentLabel: string
    setValue: (value: string) => void
}

const StyledTab = ({ label, currentLabel, setValue }: StyledTabProps): JSX.Element => (
    <Tab
        color="secondary"
        value={label}
        disableRipple
        icon={<TabChip label={label} currentLabel={currentLabel} />}
        sx={{
            paddingLeft: '4px',
            paddingRight: '4px',
            minWidth: 'unset',
            opacity: 1,
        }}
        onClick={() => setValue(label)}
    />
)

export default StyledTab
