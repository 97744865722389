import { Chip, Typography, styled } from '@mui/material'
import React, { PropsWithChildren, useRef, useState } from 'react'
import { ArrowDropDown, ArrowDropUp, Close } from '@mui/icons-material'
import { CustomPopper } from '../CustomPopper/CustomPopper'

interface ChipLinkGroupProps extends PropsWithChildren {
    label: string
}

const ChipLabelAndIcon = styled('span')(() => ({
    display: 'flex',
    alignItems: 'center',
    marginRight: -7,
    color: 'primary',
}))

export const ChipLinkGroup = ({ label, children }: ChipLinkGroupProps) => {
    const chipRef = useRef(null) as unknown as React.MutableRefObject<HTMLDivElement>
    const [open, setOpen] = useState(false)

    const toggleFilter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setOpen(!open)
        event.stopPropagation()
    }

    const onClickAway = () => {
        setOpen(!open)
    }
    return (
        <>
            <Chip
                label={
                    <ChipLabelAndIcon>
                        <Typography variant="body2" color="primary">
                            {label}
                        </Typography>
                        {open ? <ArrowDropUp color="primary" /> : <ArrowDropDown color="primary" />}
                    </ChipLabelAndIcon>
                }
                onClick={toggleFilter}
                sx={{ zIndex: open ? 2 : 'unset', padding: 3, borderRadius: '24px' }}
                aria-describedby="filter-item-chip"
                ref={chipRef}
                variant="outlined"
                color="primary"
            />
            <CustomPopper label={label} headerIcon={<Close />} open={open} anchor={chipRef.current} onClickAway={onClickAway}>
                <>{children}</>
            </CustomPopper>
        </>
    )
}
