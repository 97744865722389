import React from 'react'

import { IUtbetalningsbeslutAGE } from '../../../../Ekonomi.types'
import { formatAmount, formatDateFromDateTime } from '../../../../../../utils/helpers'

import { Card, Grid2 as Grid, Stack, Typography } from '@mui/material'
import { getUtbetalningTitle } from '@local/src/pages/Ekonomi/Ekonomi.helpers'

interface Props {
    utbetalning: IUtbetalningsbeslutAGE
}

export const AgeDetaljer = ({ utbetalning }: Props) => {
    return (
        <Card
            variant="outlined"
            sx={{
                my: 2,
                p: 2,
                alignItems: 'flex-start',
                borderRadius: 1,
                paddingRight: '55px',
            }}
            data-testid={`ekonomi-utbetalning-${utbetalning.beslutId}`}
        >
            <Grid container spacing={1}>
                <Grid size={{ xs: 12 }} pb={2}>
                    <Typography variant="h5">{getUtbetalningTitle(utbetalning.typ, utbetalning.kategori)}</Typography>
                </Grid>

                <Grid size={{ sm: 2, xs: 6 }}>
                    <Typography variant="body1">Dagar</Typography>
                    <Typography variant="body2" color="text.secondary">
                        {utbetalning.utbetaldaDagar}
                    </Typography>
                </Grid>

                <Grid size={{ sm: 4, xs: 6 }}>
                    <Typography variant="body1" noWrap>
                        Utbetalningsdatum
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {formatDateFromDateTime(utbetalning.datum ?? '')}
                    </Typography>
                </Grid>

                <Grid size={{ sm: 3, xs: 6 }}>
                    <Stack>
                        <Typography variant="body1">Nettobelopp</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {formatAmount(utbetalning.nettobelopp ?? 0)} kr
                        </Typography>
                    </Stack>
                </Grid>

                <Grid size={{ sm: 3, xs: 6 }}>
                    <Stack>
                        <Typography variant="body1">Bruttobelopp</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {formatAmount(utbetalning.bruttobelopp ?? 0)} kr
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Card>
    )
}
