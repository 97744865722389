import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, useMediaQuery, useTheme } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface IBoxAccordion {
    children: React.ReactNode
    header?: React.ReactNode
}

const BoxAccordion = ({ header, children }: IBoxAccordion) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    if (!isMobile) return children

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ my: 2, mx: 1 }} />}>{header}</AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    )
}

export default BoxAccordion
