import React from 'react'
import { isEmpty, isNil } from 'ramda'
import EkonomiCard from '../EkonomiCard'

import { IUtbetalningsbeslut, KategoriType } from '../../Ekonomi.types'
import { ekonomiCardContent, ekonomiContent, filterByKategori } from '../../Ekonomi.helpers'

import { Box, Typography } from '@mui/material'

export interface IEkonomiTab {
    utbetalningsbeslut: IUtbetalningsbeslut[]
    tabKey: string
}

export const EkonomiTab: React.FC<IEkonomiTab> = ({ utbetalningsbeslut, tabKey }: IEkonomiTab) => {
    const filteredUtbetalningsbeslut = () => {
        const insatserStudiestodKategoriTypes: KategoriType[] = ['insats', 'studieersattning', 'faktura', 'kompletterandestudiestod']
        const ageKategoriTypes: KategoriType[] = ['age']

        switch (tabKey) {
            case ekonomiContent.tabsKeys.insatserStudiestod:
                return filterByKategori(utbetalningsbeslut, insatserStudiestodKategoriTypes)
            case ekonomiContent.tabsKeys.age:
                return filterByKategori(utbetalningsbeslut, ageKategoriTypes)
            default:
                return utbetalningsbeslut
        }
    }

    const noPayments = isNil(filteredUtbetalningsbeslut()) || isEmpty(filteredUtbetalningsbeslut())

    if (noPayments)
        return (
            <Typography variant="body2">
                {tabKey === ekonomiContent.tabsKeys.alla ? 'Det finns inga utbetalningar för denna kund' : 'Det finns inga utbetalningar'}
            </Typography>
        )

    return (
        <Box pt={2}>
            <section data-testid={tabKey}>
                <article>
                    <Typography variant="body2">{ekonomiContent.informationUtbetalningsDagar}</Typography>
                    <Typography variant="caption" color="text.secondary" sx={{ mb: 2 }}>
                        {ekonomiContent.socialaAvgifter}
                    </Typography>

                    <section data-testid={`${tabKey}-utbetalningar`}>
                        {filteredUtbetalningsbeslut()?.map((beslut) => (
                            <EkonomiCard
                                utbetalningsbeslut={beslut}
                                content={ekonomiCardContent}
                                key={`${beslut.beslutId}-${beslut.betalningId}`}
                            />
                        ))}
                    </section>
                </article>
            </section>
        </Box>
    )
}
