import React, { FC } from 'react'

import { isFaktura, isInsats } from '../../../Ekonomi.helpers'
import { KategoriType, PaymentType } from '../../../Ekonomi.types'

import { formatAmount } from '@local/src/utils/helpers'
import { Stack, Typography } from '@mui/material'

interface IBruttoBelopp {
    bruttobelopp: number
    betalningsTyp: PaymentType
    bruttobeloppContent: string
    kategori: KategoriType
}

export const Bruttobelopp: FC<React.PropsWithChildren<IBruttoBelopp>> = ({
    bruttobelopp,
    bruttobeloppContent,
    kategori,
    betalningsTyp,
}) => {
    const isAterbetalning = betalningsTyp === 'komplStudiestodAterbetalning'
    const formattedAmount = formatAmount(Math.abs(bruttobelopp)) + ' kr'

    let belopp: string
    if (isFaktura(kategori)) {
        belopp = `${formatAmount(bruttobelopp)} kr`
    } else if (isAterbetalning) {
        belopp = `-${formattedAmount}`
    } else {
        belopp = formattedAmount
    }

    return (
        <Stack direction="column">
            <Typography variant="body1">{`${bruttobeloppContent}${isInsats(kategori) ? '*' : ''} `}</Typography>
            <Typography variant="body2" color="text.secondary">
                {belopp}
            </Typography>
        </Stack>
    )
}
