import useGetArendeId from './useGetArendeId'
import { useGetArendeQuery } from '@local/src/api'

const useIsErrorGetArende = () => {
    const arendeId = useGetArendeId()
    const { isError } = useGetArendeQuery(arendeId)

    return isError
}

export default useIsErrorGetArende
