import React from 'react'

import { isAge, isFaktura, isInsats, isKompletterandeStudiestod, isStudieersattning } from '../../../Ekonomi.helpers'
import { KategoriType } from '../../../Ekonomi.types'

import { formatDateFromDateTime } from '@local/src/utils/helpers'
import { Stack, Typography } from '@mui/material'

export const Datum = ({
    datum,
    statusDatumLabel,
    utbetalningsDatumLabel,
    bokforingsDatumLabel,
    datumSaknasLabel,
    kategori,
}: {
    datum: string
    statusDatumLabel: string
    utbetalningsDatumLabel: string
    bokforingsDatumLabel: string
    datumSaknasLabel: string
    kategori: KategoriType
}) => {
    const getDateLabel = () => {
        if (isAge(kategori)) return utbetalningsDatumLabel
        if (isInsats(kategori) || isStudieersattning(kategori) || isKompletterandeStudiestod(kategori)) return statusDatumLabel
        if (isFaktura(kategori)) return bokforingsDatumLabel
    }
    return (
        <Stack direction="column">
            <Typography variant="body1" noWrap>
                {getDateLabel()}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {datum ? formatDateFromDateTime(datum) : datumSaknasLabel}
            </Typography>
        </Stack>
    )
}
