import { useRouteMatch } from 'react-router-dom'

import { ekonomiTabPage } from '../Ekonomi.types'

const useGetArendeId = () => {
    const data = useRouteMatch<{ arendeId: string }>({
        path: ekonomiTabPage,
    })

    return data?.params?.arendeId as string
}
export default useGetArendeId
